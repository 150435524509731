import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/button.css"

const IndexPage = () => (
  <Layout>
    <SEO />
    <div
      style={{
        float: `left`,
        width: `100%`,
        padding: `2.6em 0 6em`,
        textAlign: `center`,
      }}
    >
      <h2
        style={{
          marginBottom: `.5em`,
          fontFamily: `Spurs`,
          fontSize: `4em`,
          color: `#fff`,
          textTransform: `uppercase`,
          letterSpacing: `.05em`,
        }}
      >
        Spurs
      </h2>
      <h4
        style={{
          marginBottom: `2em`,
          fontSize: `1.2em`,
          color: `#fff`,
          fontWeight: `normal`,
        }}
      >
        Social Wall for Tottenham Hotspur FC.
      </h4>
      <Link to="/live-iptv" className="button">
        Live IPTV - HD
      </Link>
      <Link to="/live-iptv-hsbc" className="button">
        Live IPTV HSBC - HD
      </Link>
      <Link to="/live-iptv-icc" className="button">
        ICC IPTV 2019 - HD
      </Link>
      <Link to="/bowl" className="button">
        Bowl - HD
      </Link>
      <Link to="/bowl-hsbc" className="button">
        Bowl HSBC - HD
      </Link>
      <Link to="/bowl-nfl" className="button">
        Bowl NFL UK - HD
      </Link>
      <Link to="/te-media-cafe" className="button">
        TE &amp; Media Cafe - 4K
      </Link>
      <Link to="/ticker-tape-vertical" className="button">
        Ticker Tape Vertical
      </Link>
      <Link to="/ticker-tape-banquet" className="button">
        Ticker Tape Banquet
      </Link>
    </div>
  </Layout>
)

export default IndexPage
